import createExpandedRowFromColumns from "@/components/Table/createExpandedRowFromColumns";
import {Typography} from "antd";
import React, {useCallback, useEffect, useState} from 'react';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import MyPageContainer from "@/components/MyPageContainer";
import Table from "@/components/Table";
import type {ProColumns} from "@ant-design/pro-table";
import {DispatchFunction} from "@/typings/models/ReduxState";
import {ServiceRequestModel, ServiceRequestTableData} from "@/typings/models/ServiceRequest";
import {CustomerModel} from "@/typings/models/Customer";
import CustomerName from "@/components/Customer/CustomerName";
import debounce from "lodash/debounce";
import {getExportURL} from "@/utils/functions";
import {EngagementScore} from "../Customers/EngagementScore";


interface ServiceRequestProps {
  dispatch: DispatchFunction,
  list: ServiceRequestTableData,
  loading: boolean
}

const REDUX_ACTION = 'serviceRequest/fetch'
const ServiceRequests: React.FC<ServiceRequestProps> = ({dispatch, list, loading, date}) => {

  const intl = useIntl();
  const [tablePayload, setTablePayload] = useState({})


  const fetchTableData = useCallback(debounce((payload = tablePayload) => {
    setTablePayload(payload)
    dispatch({
      type: REDUX_ACTION,
      payload
    })
  }, 200), [])

  useEffect(() => {
    fetchTableData()
  }, [date.updatedAt])

  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.requests.customer" defaultMessage="Customer" />,
      dataIndex: 'Customer',
      // TODO: If we want to open a drawer, the customer string id should be sent in the API response
      render: (customer: CustomerModel) => <CustomerName customer={customer} />,
    },
    {
      title: <F id="pages.customers.engagementScore" defaultMessage="Engagement Score" />,
      tooltip: (
        <F
          id="pages.customers.scoreExplanation"
          defaultMessage="Estimated probability this customer will buy in your store based on all previous interactions."
        />
      ),
      dataIndex: 'engagement_score',
      render: (customer: CustomerModel) => (
        <div style={{ textAlign: 'center' }}>
          {customer?.engagement_score ? <EngagementScore score={customer?.engagement_score} /> : ' - '}
        </div>
      ),
    },
    {
      title: <F id="pages.customers.utmMedium" defaultMessage="UTM Medium" />,
      dataIndex: 'utm_medium',
      render:  (customer: CustomerModel) => (
        <Typography.Text>{customer.utm_medium}</Typography.Text>
      ),
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.requests.appointmentRequest" defaultMessage="Appointment Request" />,
      dataIndex: 'ServiceRequest',
      render: (serviceRequest: ServiceRequestModel) => (
        <Typography.Text>{serviceRequest.when}</Typography.Text>
      ),
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.requests.what" defaultMessage="What" />,
      dataIndex: 'ServiceRequest',
      render: (serviceRequest: ServiceRequestModel) => (
        <Typography.Text>{serviceRequest.what}</Typography.Text>
      ),
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.requests.dateOfRequest" defaultMessage="Date of Request" />,
      dataIndex: 'ServiceRequest',
      render: (serviceRequest: ServiceRequestModel) => (
        <Typography.Text>{serviceRequest.created}</Typography.Text>
      ),
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: <F id="pages.requests.where" defaultMessage="Where" />,
      dataIndex: 'ServiceRequest',
      render: (serviceRequest: ServiceRequestModel) => (
        <Typography.Text>{serviceRequest.where}</Typography.Text>
      ),
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.requests.actions" defaultMessage="Actions" />,
      render: (_, serviceRequest: ServiceRequestModel) => (
        <a
          onClick={() =>
            dispatch({
              type: 'serviceRequest/toggleDrawer',
              payload: {
                serviceRequest,
              },
            })
          }
        >
          <F id="pages.requests.details" defaultMessage=" Details " />
        </a>
      ),
      dataIndex: 'actions',
    },
  ];

  return (
    <MyPageContainer extraProps={{exportTable: { downloadAction: 'serviceRequest/downloadExport', url: getExportURL(list) }, hasRangePicker: true}}>
      <Table headerTitle={intl.formatMessage({
        id: 'pages.requests.tableTitle',
        defaultMessage: 'Requests',
      })}
             rowKey={"id"}
             columns={columns}
             links={list.links}
             total={list.total}
             dataSource={(list.items || []).map((item, id) => ({...item, id}))} loading={loading}
             request={fetchTableData}
             expandableRow={createExpandedRowFromColumns<ServiceRequestModel>(columns)}
      />
    </MyPageContainer>
  );
};

export default connect(({serviceRequest, loading, date}) => ({
  list: serviceRequest.list,
  date,
  loading: loading.effects[REDUX_ACTION]
}))(ServiceRequests);
