import { UserRole } from "@/typings/models/User";
import { PARTNER } from "@/typings/roleMapping";

export const DEFAULT_USER_ROLE: UserRole = { role: PARTNER, label: PARTNER }

export const getPersistedUserRoleOnLocalStorage: () => UserRole | null = () => {
    if (typeof localStorage === 'undefined') return null;
  
    let devModeRole: UserRole | null = null;
    try {
      const devModeRoleString = localStorage.getItem('devModeRole');
      if (devModeRoleString) {
        devModeRole = JSON.parse(devModeRoleString);
      }
    } catch (error) {
      console.error('Error parsing devModeRole from localStorage:', error);
    }
    return devModeRole;
  };
  export const setPersistedUserRoleOnLocalStorage: (selectedRole: string) => void = (selectedRole: string) => {
    if (typeof localStorage === 'undefined') return;
  
    const role = { role: selectedRole, label: selectedRole };
  
    try {
      const roleString = JSON.stringify(role);
      localStorage.setItem('devModeRole', roleString);
    } catch (error) {
      console.error('Error setting devModeRole in localStorage:', error);
    }
  };