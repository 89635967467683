import React, {useEffect, useRef} from "react";
import {useDispatch, useModel, useSelector} from "umi";
import CustomerDrawer from "@/components/CustomerDrawer";
import LocationDrawer from "@/components/LocationDrawer";
import UserDrawer from "@/components/UserDrawer";
import ServiceRequestDrawer from "@/components/ServiceRequestDrawer";
import PromotionDrawer from "@/components/PromotionDrawer";


// const journeyWatcherActions = ["serviceRequest/fetch", "customers/fetch", "customers/fetchStates", "analytics/init", "location/init", {
//   type: "serviceRequest/fetchInfinite",
//   payload: {
//     reset: true
//   }
// }]

const DvaWatcher: React.FC = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const store: DefaultRootState = useSelector((store) => store);
  const { initialState, setInitialState } = useModel('@@initialState');

  const customerDrawer = store.customers.drawer;
  const serviceRequestDrawer = store.serviceRequest.drawer;

  function checkJourneyChange() {
    const prev = prevStore.current;
    if (
      store?.journey?.selectedJourney?.hashId &&
      prev?.journey?.selectedJourney?.hashId !== store?.journey?.selectedJourney?.hashId
    ) {
      dispatch({
        type: 'journey/fetchConfig',
        payload: { journeyId: store?.journey?.selectedJourney?.hashId },
      });
    }
  }

  const prevStore = useRef<DefaultRootState>();
  useEffect(() => {
    if (prevStore.current) {
      checkJourneyChange();
    }
    prevStore.current = store;
  }, [store]);

  useEffect(() => {
    if (initialState && initialState?.currentUser?.Journeys) {
      dispatch({
        type: 'journey/gotJourneys',
        payload: initialState.currentUser.Journeys,
      });
    }
  }, [initialState]);

  useEffect(() => {
    setInitialState({
      ...initialState,
      config: { ...initialState?.config, ...store?.journey?.config },
    });
  }, [JSON.stringify(store?.journey?.config)]);
  return (
    <>
      {!customerDrawer?.nested && <CustomerDrawer />}
      {!serviceRequestDrawer?.nested && <ServiceRequestDrawer />}
      <LocationDrawer />
      <PromotionDrawer />
      <UserDrawer />
    </>
  );
};

export default DvaWatcher
