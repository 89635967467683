import { transformObjectToLocaleMap } from '@/utils/intl';

export default {
  // region Login Page
  'pages.login.welcome': 'Welkom! Aanmelden',
  'pages.layouts.userLayout.title': 'Begin uw keuken verkoopgesprek online',
  'pages.login.username.placeholder': 'Mailadres',
  'pages.login.password.placeholder': 'Wachtwoord',
  'pages.login.rememberMe': 'Herinner mij',
  'pages.login.forgotPassword': 'Wachtwoord vergeten ?',
  'pages.login.dontHaveAnAccount': 'Heb je geen account?',
  'pages.login.requestDemo': 'Een demo aanvragen',
  'pages.login.submit': 'Inloggen',
  'pages.login.password.required': 'Voer uw wachtwoord in.',
  'pages.login.failure': 'Inloggen mislukt, probeer het opnieuw.',
  'pages.login.accountLogin.errorMessage': 'Onjuiste gebruikersnaam/wachtwoord',
  'pages.login.username.required': 'Voer uw gebruikersnaam in.',
  'pages.login.success': 'Inloggen succesvol!',
  'pages.login.loginTitle': 'Welkom! Aanmelden',
  'pages.login.dontHaveAccount': 'Heb je geen account?',
  'app.footer.imprint': 'Impressum',
  'app.footer.policy': 'Privacybeleid',
  'pages.login.email.required': 'Voer e-mail in',
  // endregion

  // region Reset Password Page
  'pages.resetPassword.setNewPassword': 'Nieuw wachtwoord instellen',
  'pages.resetPassword.placeholder': 'Wachtwoord',
  'pages.resetPassword.placeholderConfirm': 'Bevestig wachtwoord',
  'pages.resetPassword.passwordRequired': 'Voer uw wachtwoord in.',
  'pages.resetPassword.backToLogin': 'Terug naar Inloggen',
  'pages.resetPassword.title': 'Een nieuw wachtwoord toewijzen',
  'pages.resetPassword.subTitle': 'Voer een nieuw wachtwoord in',
  'pages.resetPassword.errorMessage':
    'Uw wachtwoord moet uit minstens 8 tekens bestaan, een hoofdletter, een kleine letter, een cijfer en een speciaal teken.',
  'pages.resetPassword.wrongConfirmation':
    'De twee wachtwoorden die u invoerde komen niet overeen.',
  'pages.forgotPassword.hint':
    'Naar welk e-mailadres moet de informatie voor het opnieuw instellen van het wachtwoord worden gestuurd?',
  // endregion

  // region Forgot Password Page
  'pages.forgotPassword.label': 'Mailadres',
  'pages.forgotPassword.required': 'Dit veld is verplicht! Typ uw email adres',
  'pages.forgotPassword.placeholder': 'Mailadres',
  'pages.forgotPassword.tooltip':
    'Als u uw e-mailadres niet meer weet, neem dan contact met ons op via support@conversional.de',
  'pages.forgotPassword.errorMessage':
    'Er is een fout met uw email adres, neem contact op met support via de contact pagina.',
  'pages.forgotPassword.invalid':
    'Het door u ingevoerde e-mailadres is niet geregistreerd. Voer het juiste e-mailadres in.',
  'pages.forgotPassword.submit': 'Aanvraag verzenden',
  'pages.forgotPassword.login': 'Terug naar Inloggen',
  'pages.forgotPassword.guidText':
    'Naar welk e-mailadres moet de informatie voor het opnieuw instellen van het wachtwoord worden gestuurd?',
  'pages.forgotPassword.sentMail': 'Reset wachtwoord e-mail succesvol verzonden.',
  'pages.forgotPassword.sentMailSubtitle': 'Controleer ook je Spam.',
  'pages.forgotPassword.forgotPasswordTitle': 'Reset Wachtwoord',
  // endregion

  // region Dashboard Page
  'pages.dashboard.header': 'Dashboard',
  // endregion

  // region Marketing Page
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'Geen diagram beschikbaar. Probeer de datumfilter te veranderen',
  // endregion

  // region Performance Page
  'pages.performance.header': 'Performance',
  'pages.performance.alertMessage': 'Dit is de performance pagina',
  'pages.performance.cardTitle': 'Performance Funnel',
  'pages.performance.infoText':
    'Deze gegevens zijn gebaseerd op het traceren van gebeurtenissen. Door tracking blockers en sampling worden niet alle gebruikers geregistreerd. Er kunnen afwijkingen zijn ten opzichte van het dashboard.',
  'pages.performance.paths': 'Paden',
  'pages.performance.explanation':
    'Paden worden gestart vanuit de vragenlijst. De waarden van "gestart" verwijzen naar het pad, niet naar het starten van de tool. Daarom is de start van alle paden lager dan het starten van de tool als gebruikers in de eerste stap annuleren.',

  // endregion

  // region Customers Page
  'pages.customers.header': 'Klanten',
  'pages.customers.tableTitle': 'Klanten',
  'pages.customers.engagementScore': 'Betrokkenheidsscore',
  'pages.customers.scoreExplanation':
    'Geschatte kans dat deze klant in uw winkel zal kopen op basis van alle eerdere interacties.',
  'pages.customers.name': 'Naam',
  'pages.customers.gender': 'Geslacht',
  'pages.customers.email': 'Mailadres',
  'pages.customers.utmMedium': 'UTM Medium',
  'pages.customers.status': 'Status',
  'pages.customers.avtiveRecipient': 'Actieve ontvanger',
  'pages.customers.doubleOptin': 'Dubbele opt-in',
  'pages.customers.createDate': 'Aanmaakdatum',
  'pages.customers.actions': 'Acties',
  'pages.customers.details': 'Details',
  'pages.customers.actions.download': 'CSV downloaden',
  'pages.customers.profile': 'Profile',
  'pages.customers.firstName': 'Voornaam',
  'pages.customers.lastName': 'Achternaam',
  'pages.customers.styles': 'Styles',
  'pages.customers.qualification': 'Kwalificatie',
  'pages.customers.phone': 'Telefoon',
  'pages.customers.activeRecipient': 'Actieve ontvanger',
  'pages.customer.unsubscribe': 'Uitschrijven',
  'pages.customer.qualification': 'Kwalificatie',
  'pages.customer.state': 'Status',
  'pages.customer.orderVolume': 'Order Volume',
  'pages.customer.notes': 'Opmerkingen',
  'pages.customer.results': 'Resultaten',
  'pages.customer.uploadedFiles': 'Geüploade Bestanden',
  'pages.customer.noUploadedFiles': 'Geen Geüploade Bestanden',

  // endregion

  // region Requests Page
  'pages.requests.header': 'Klanten',
  'pages.requests.tableTitle': 'Aanvragen',
  'pages.requests.customer': 'Klant',
  'pages.requests.appointmentRequest': 'Afspraak Aanvraag',
  'pages.requests.what': 'Wat',
  'pages.requests.dateOfRequest': 'Datum van aanvraag',
  'pages.requests.where': 'Waar',
  'pages.requests.actions': 'Acties',
  'pages.requests.details': 'Details',
  // endregion

  // region Locations page
  'pages.locations.header': 'Locaties',
  'pages.locations.alertMessage': 'Dit is de locaties pagina',
  'pages.locations.location': 'Locatie',
  'pages.locations.address': 'Adres',
  'pages.locations.videoCall': 'Video oproep',
  'pages.locations.created': 'Aanmaakdatum',
  'pages.locations.actions': 'Acties',
  'pages.locations.details': 'Details',
  'pages.location.edit': 'Bewerken',
  'pages.locations.availability': 'Open Hours',
  'pages.locations.blockHours': 'Block Hours',
  'pages.locations.additionalOpenHours': 'Additional Open Hours',
  'pages.locations.from': 'Van',
  'pages.locations.fromHour': 'Van',
  'pages.locations.to': 'Tot',
  'pages.locations.toHour': 'Tot',
  'pages.location.removeRecurringTime':
    'Ben je zeker dat je deze terugkerende tijd wil verwijderen?',
  'pages.locations.download': 'CSV downloaden',
  'pages.common.add': 'Add',
  'pages.common.remove': 'Verwijderen',
  'pages.common.actions': 'Acties',
  'pages.common.clear': 'Deselecteren',
  'pages.common.clearAll': 'Alles wissen',
  'pages.common.save': 'Redden',
  'pages.common.discard': 'Weggooien',
  'pages.common.selected': 'Geselecteerd',
  'pages.common.form.required': 'This Field is Required.',
  'pages.common.edit': 'Bewerken',
  'pages.common.yes': 'ja',
  'pages.common.no': 'nee',
  'pages.common.ok': 'OK',
  'pages.common.cancel': 'Annuleren',
  'pages.common.tutorial': 'Tutorial',
  'pages.common.confirm': 'Verder',
  'pages.images.deleteSelectedImagesSuccess': 'Geselecteerde afbeeldingen zijn verwijderd.',
  'pages.location.removeBlockPeriod': 'Weet je zeker dat je deze blok periode wilt verwijderen?',
  'pages.location.removeOpenPeriod': 'Ben je zeker dat je deze open periode wil verwijderen?',
  'pages.location.name': 'Naam',
  'pages.location.home': 'Thuis',
  'pages.location.store': 'Filialen',
  'pages.location.online': 'Online',
  'pages.location.type': 'Type',
  'pages.location.type_tooltip':
    'Het type locatie bepaalt wat voor soort afspraak er wordt geboekt.',
  'pages.location.address': 'Adres',
  'pages.location.createDate': 'Aanmaakdatum',
  'pages.location.videoCallEnabled': 'Actief',
  'pages.location.videoCallDisabled': 'Inactief',
  'pages.location.videoCall': 'Video oproep',
  'pages.location.details': 'Details',
  'pages.locations.currentRecurring': 'Huidige terugkerende tijd',
  'pages.locations.resources': 'Hulpbron',
  'pages.locations.meta': 'Meta',
  'pages.locations.resourceCountLimitation':
    'U kunt niet meer dan 5 hulpbronnen aan een locatie toevoegen. Als u meer hulpbronnen nodig heeft, neem dan contact op met support.',
  'pages.locations.atLeastOneResource': 'Elke locatie vereist ten minste één hulpbron.',
  'pages.location.removeResource': 'Weet u zeker dat u deze hulpbron wilt verwijderen?',
  'pages.locations.addNewResource': 'Nieuwe hulpbronnen toevoegen',
  'pages.locations.removeResourceSuccess': 'Hulpbron is verwijderd.',
  'pages.locations.removeResourceFailed': 'Hulpbron kon niet worden verwijderd.',
  'pages.locations.addNewResourceSuccess': 'Nieuwe hulpbron toegevoegd.',
  'pages.locations.addNewResourceFailed': 'Hulpbron kon niet worden toegevoegd.',
  'pages.location.resource.name': 'Naam',
  'pages.location.resource.email': 'Mailadres',
  // Location Removal
  'pages.locations.removeLocationConfirm': 'Weet je zeker dat je deze locatie wilt verwijderen?',
  'pages.locations.removeLocationSuccess': 'Locatie succesvol verwijderd',
  'pages.locations.removeLocationFailed': 'Locatie niet verwijderd',
  //
  'pages.resource.name': 'Naam',
  'pages.resource.surname': 'Achternaam',
  'pages.resource.email': 'Mailadres',

  // endregion

  // region Account Settings
  'pages.settings.header': 'Account Settings',
  'pages.settings.profileTab': 'Profiel',
  'pages.settings.billingTab': 'Facturering',
  'pages.settings.languageTab': 'Taal',
  'pages.settings.firstName.label': 'Voornaam',
  'pages.settings.firstName.required': 'Dit veld is verplicht! Type uw voornaam.',
  'pages.settings.firstName.placeholder': 'Voornaam',
  'pages.settings.firstName.errorMessage':
    'Uw voornaam mag geen cijfers en symbolen bevatten. Gelieve uw voornaam te corrigeren.',
  'pages.settings.lastName.label': 'Achternaam',
  'pages.settings.lastName.required': 'Dit veld is verplicht! Type uw achternaam.',
  'pages.settings.lastName.placeholder': 'Achternaam',
  'pages.settings.lastName.errorMessage':
    'Uw achternaam mag geen cijfers en symbolen bevatten. Gelieve uw achternaam te corrigeren.',
  'pages.settings.email.label': 'Mailadres',
  'pages.settings.email.required': 'Dit veld is verplicht! Typ uw mailadres.',
  'pages.settings.email.placeholder': 'Mailadres',
  'pages.settings.email.errorMessage':
    'Er is een fout met uw e-mail adres, gelieve uw e-mail in een geldig formaat.',
  'pages.settings.phone.label': 'Telefoon',
  'pages.settings.phone.required': 'Dit veld is verplicht! Typ uw telefoon.',
  'pages.settings.phone.placeholder': 'Telefoon',
  'pages.settings.phone.errorMessage':
    'Your phone can not contains characters. Please correct your phone number.',
  'pages.settings.address.label': 'Adres',
  'pages.settings.address.required': 'Dit veld is verplicht! Typ uw adres.',
  'pages.settings.address.placeholder': 'Adres',
  'pages.settings.saveSettings': 'Wijzigingen opslaan',

  // region Account Settings page - Billings tab
  'pages.billings.header': 'Factureringen',
  'pages.billings.alertMessage': 'Dit is de Factureringen pagina',
  'pages.billings.questionnaire': 'Vragenlijst',
  'pages.billings.customer': 'Klant',
  'pages.billings.journey': 'Journey',
  'pages.billings.event': 'Evenement',
  'pages.billings.period': 'Periode',
  'pages.billings.created': 'Gemaakt',
  'pages.billings.mode': 'Modus',
  'pages.billings.subtotal': 'Subtotaal',
  'pages.billings.discount': 'Korting',
  'pages.billings.total': 'Totaal',
  'pages.BillingDetails': 'Factureringsdetails',
  // endregion

  // region Account Settings page - Language tab
  'pages.language.title': 'Selecteer uw taal',
  'pages.language.english': 'Engels',
  'pages.language.german': 'Duits',
  'pages.language.french': 'Frans',
  // endregion
  // endregion

  // region Profile Page
  'pages.profile.firstName': 'Voornaam',
  'pages.profile.lastName': 'Achternaam',
  'pages.profile.email': 'Mailadres',
  'pages.profile.phone': 'Telefoon',
  'pages.profile.address': 'Adres',
  'pages.profile.saveChanges': 'Save Changes',
  // endregion

  // region Support Page
  'pages.support.header': 'Steun',
  'pages.settings.telephone': 'Telefoon',
  'pages.settings.email': 'Mailadres',
  'pages.support.telephone': 'Telefoon',
  'pages.support.email': 'Mailadres',
  'pages.support.title': 'Neem contact op met onze technische steun',
  // endregion

  // region Service Request
  'pages.serviceRequest.customer': 'Klant',
  'pages.customers.created': 'Gemaakt op',
  'pages.serviceRequest.inquiry': 'Onderzoek',
  'pages.serviceRequest.what': 'Wat',
  'pages.serviceRequest.when': 'Wanneer',
  'pages.serviceRequest.where': 'Waar',
  'pages.serviceRequest.qualification': 'Kwalificatie',
  // endregion

  // region Date and Weekdays
  'utils.date.7Days': 'Laatste 7 dagen',
  'utils.date.30Days': 'Laatste 30 dagen',
  'utils.date.90Days': 'Laatste 90 dagen',
  'utils.date.180Days': 'Laatste 180 dagen',
  // endregion

  // region Images Page

  'pages.images.tagGroups': 'Tag groepen',
  'pages.images.createTagGroup': 'Tag groep aanmaken',
  'pages.images.editTag': 'Bewerk tag',

  'pages.images.tags': 'Tags',
  'pages.images.removeImageConfirmation':
    'Weet u zeker dat u de afbeelding wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.',
  'pages.images.chooseGroup': 'Selecteer een groep voor de tag:',
  'pages.images.newTag': 'Nieuwe tag',
  'pages.images.createTag': 'Tag aanmaken',
  'pages.images.remove': 'Verwijderen',
  'pages.images.removeTagGloballyTitle': 'Weet u zeker dat u de tag wilt verwijderen?',
  'pages.images.removeTagGloballySubTitle': 'De tag wordt dan van alle afbeeldingen verwijderd.',
  'pages.images.upload': 'Uploaden',
  'pages.images.uploadDrawerTitle': 'Afbeelding uploaden',
  'pages.images.uploadDraggerHint': 'Afbeelding in de hoogst mogelijke resolutie uploaden',
  'pages.images.uploadDraggerText':
    'Klik of sleep bestanden of mappen naar dit gebied om deze te uploaden',
  'pages.images.file': 'Bestand',
  'pages.images.directory': 'Map',
  'pages.images.selectToBulkEdit': 'Selecteer afbeeldingen die u in bulk wilt taggen',
  'pages.images.selectTagStatistics': 'Statistieken',
  'pages.images.deleteSelectedImagesConfirm': 'Wil je (x) afbeeldingen verwijderen?',
  'pages.images.contactSupportToSetUpImageGallery':
    'Je gebruikt momenteel geen eigen afbeeldingsgalerie. Als je meer controle wilt over het beeldmateriaal, dan kun je hier je eigen galerij aanvragen.',
  'pages.images.noCustomImageGalleryAvailable': 'U heeft nog geen aangepaste afbeeldingengalerij.',
  'pages.images.requestAccess': 'Je eigen galerij aanvragen',

  // endregion

  // region CTA Page
  'pages.cta.performance': 'Click / Conversion',
  'pages.cta.page': 'Website',
  'pages.cta.conversions.help':
    'Gebruikers die de vragenlijst met en zonder afspraak hebben ingevuld',
  // endregion

  // region Promotions Page

  'pages.promotions.help_1': 'Wat zijn promoties?',
  'pages.promotions.help_2':
    'Promoties voegen automatisch actuele promoties toe aan je e-mails. Zodra je een promotie aanmaakt en activeert, worden klanten gedurende de aangegeven periode op de hoogte gebracht van deze promotie.',
  'pages.promotions.tableTitle': 'Promoties',
  'pages.promotions.showVideoTutorial': 'Toon videotutorial',
  'pages.promotions.newPromotion': 'Nieuwe promotie',
  'pages.promotions.editPromotion': 'Promotie bewerken',
  'pages.promotions.addPromotion': 'Voeg  toe',
  'pages.promotions.removePromotionConfirm': 'Weet je zeker dat je deze promotie wilt verwijderen?',
  'pages.promotions.type': 'Type promotie',
  'pages.promotions.modifiedDate': 'Laatst gewijzigd',
  'pages.promotions.title': 'Titel',
  'pages.promotions.start': 'Start',
  'pages.promotions.end': 'Einde',
  'pages.promotions.dates': 'Runtime',
  'pages.promotions.removePromotionsSuccess': 'Promotie geannuleerd',
  'pages.promotions.removePromotionsFailed': 'Promotie kon niet worden verwijderd',
  'pages.promotions.form': 'Promotieformulier',
  'pages.promotions.preview': 'Voorbeeld',
  'pages.promotions.promotionCondition': 'Algemene voorwaarden',
  'pages.promotions.addPromotionCondition': 'Algemene voorwaarden toevoegen',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Selecteer',
  'pages.promotions.invalid_error':'Voer een geldige URL in',

  // endregion

  //tag statistics
  'pages.stats.selectTagGroups': 'Selecteer maximaal 3 taggroepen die u wilt vergelijken',
  'pages.stats.tagCombination': 'Tag Combinatie',
  'pages.stats.frequency': 'Frequentie',
  //endregion

  // region Settings - visibility
  'pages.settings.visibility.active': 'Actief',
  'pages.settings.visibility.active.tooltip': "Je kunt je Journey op alle pagina's deactiveren",
  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: 'Je kunt een URL of een reguliere expressie invoeren, voor filters die sites dekken op basis van regels.',
    exampleTitle: 'Voorbeelden',
    urls: [
      {
        value: '/keuken/i',
        description: 'Alle pagina-URL\'s die "keuken" bevatten',
      },
      {
        value: '/keuken/(.*)/i',
        description: 'Alle keukensubsites die "keuken/" bevatten',
      },
      {
        value: '/https://domain.com/keuken/i',
        description: 'Alle pagina\'s die beginnen met "https://domain.com/keuken"',
      },
    ],
  }),
  'pages.settings.visibility.blackList': "Pagina's uitsluiten",
  'pages.settings.visibility.blackList.tooltip':
    "De journey wordt alleen weergegeven op de hier vermelde pagina's",
  'pages.settings.visibility.whiteList': 'Alleen weergeven op',
  'pages.settings.visibility.whiteList.tooltip':
    "De Journey wordt weergegeven op alle pagina's behalve de vermelde",
  'pages.settings.configuration.editSuccessMessage': 'Instellingen succesvol bijgewerkt!',
// endregion
   // region ForbiddenPage Page
   'pages.ForbiddenPage.message': 'Je hebt geen toestemming om deze pagina te openen.',
   'pages.users.newUser': 'Nieuwe gebruiker',
   'pages.users.removeUserConfirmation':'Weet je zeker dat je de gebruiker wilt verwijderen',
   'pages.users.tableTitle':'Gebruikers',
   'pages.users.user':'Gebruiker',
   'pages.users.email':'Email',
   "pages.users.role":"Rol",
   'pages.users.selectPlaceholder': 'Selecteer rol',
   'pages.users.addUser': 'Gebruiker toevoegen',
  'pages.users.close': 'Sluiten',
  'pages.users.phone':'Telefoon',
  "pages.users.removeUserSuccess":'Gebruiker succesvol verwijderd.',
  "pages.users.removeUserFailed": 'Gebruiker verwijderen mislukt.',
  "pages.users.createUserSuccess":'Gebruiker succesvol aangemaakt.',
  "pages.users.createUserFailed":'Gebruiker aanmaken mislukt.',
    "pages.users.phone.invalidFormat":'Voer een geldig telefoonnummer in.',
  'pages.users.phone.errorMessage': 'Voer een geldig telefoonnummer in.',
   
     // endregion
};
