import type {Settings as LayoutSettings} from '@ant-design/pro-layout';
import {PageLoading} from '@ant-design/pro-layout';
import {getDvaApp, history} from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import {getGlobalInitialState, persistGlobalState} from './utils/persistStore';
import * as React from 'react';
import {fetchMeRequest} from '@/services/api/user';
import {registerTheme} from '@antv/g2';
import {FONT_FAMILY} from '@/constants/theme';
import {initializeSentry} from '@/services/sentry';
import {initializeTracker, trackPageView} from '@/services/tracker';
import MenuHeader from '@/components/menu/MenuHeader';
import MenuFooter from '@/components/menu/MenuFooter';
import {initializeLocale} from "@/utils/locale";
import {API} from "@/services/api/typings";
import {BackofficeUserModel} from "@/typings/api/user";
import get from "lodash/get";
import MenuItem from "@/components/menu/MenuItem";
import {selectCurrentJourneyConfig} from "@/services/selectors";
import {checkUserAccessToPath, getRoleCustomSidebarMenuDisplayName} from './utils/access';

initializeSentry();
initializeTracker();
initializeLocale();

const loginPath = '/user/login';

/** When obtaining user information is slow, one will be displayed loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};
registerTheme('cv', {
  fontFamily: FONT_FAMILY,
  defaultColor: 'red',
});

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<IInitialState & {
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const APICall = await fetchMeRequest();
      const status = get(APICall, 'response.status');
      const data = get(APICall, 'data');
      if (status === 200) {
        if (window.location.pathname === "/") {
          history.push("/check");
        }
        return data;
      } else {
        history.push(loginPath);
      }
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  // If it is a login page, do not execute
  const { location: { pathname } } = history;
  if (!pathname.includes('/user/') && !pathname.includes('/set-password/') && !pathname.includes("/mimic")) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: {},
    };
  }
  return {
    fetchUserInfo,
    settings: {},
  };
}

type IInitialState = {
  collapsed?: boolean,
  hideFooter?: boolean,
  disableContentMargin?: boolean,
  config?: any,
  settings?: Record<string, unknown>,
  currentUser?: BackofficeUserModel,
}

type ILayoutFunctionProps = {
  initialState: IInitialState,
  currentUser: BackofficeUserModel,
  setInitialState: (state: IInitialState) => void
}

const filterMenu = (user, menuData) => menuData.filter(menu => {
  const { path } = menu;
  if (!checkUserAccessToPath(user, path)) return null;
  if (!menu.journeyConfigAccessKey) return true;
  const journeyConfig = selectCurrentJourneyConfig(window.getState());
  return get(journeyConfig, menu.journeyConfigAccessKey);
});

const processMenuItems = (user, menuItems) => {
  return menuItems.map((item) => {
    const customDisplayName = getRoleCustomSidebarMenuDisplayName(user, item.path);
    if (customDisplayName) {
      item.locale = customDisplayName;
    }
    return item;
  });
};
// ProLayout Supported api https://procomponents.ant.design/components/layout
export const layout = ({ initialState, setInitialState }: ILayoutFunctionProps) => {
  const user = initialState?.currentUser?.User

  return {
    rightContentRender: () => <RightContent />,
    siderWidth: 300,
    disableContentMargin: !!initialState.disableContentMargin,
    menuHeaderRender: (logo, title) => <MenuHeader logo={logo} />,
    menuFooterRender: () => <MenuFooter />,
    onMenuHeaderClick: () => {},
    footerRender: () => !initialState.hideFooter && <Footer />,
    onPageChange: () => {
      if (window.innerWidth < 768) {
        if (!initialState.collapsed) {
          setInitialState({
            ...initialState,
            collapsed: true,
          });
        }
      }
      trackPageView();
      const { location } = history;
      // If not logged in, redirect to login
      if (!initialState?.currentUser && !location.pathname.includes('/user/')) {
        history.push(loginPath);
      }
    },
    onCollapse: (collapsed) =>
      setInitialState({
        ...initialState,
        collapsed,
      }),
    postMenuData: (menuData) => processMenuItems(user, filterMenu(user, menuData)),
    menuItemRender: (item, dom) => <MenuItem item={item}>{dom}</MenuItem>,
    headerContentRender: () => <></>,
    collapsedButtonRender: false,
    collapsed: initialState?.collapsed,
    ...initialState?.settings,
    journeyConfig: initialState?.config,
  };
};

export const dva = {
  config: {
    initialState: getGlobalInitialState(),
    onStateChange: (state) => {
      persistGlobalState(state);
    },
    onError: () => {},
  },
};

window.g_app = () => getDvaApp();
window.getState = () => getDvaApp()._store.getState();

const mockConsoleMethod = (realConsoleMethod) => {
  const ignoredMessages = [
    '[React Intl]',
    '"key" prop',
  ];

  return (message = '', ...args) => {
    const containsIgnoredMessage = ignoredMessages.some(
      (ignoredMessage) => typeof message === 'string' && message.includes(ignoredMessage),
    );

    if (!containsIgnoredMessage) {
      realConsoleMethod(message, ...args);
    }
  };
};

console.warn = mockConsoleMethod(console.warn);
console.error = mockConsoleMethod(console.error);
