import {NavLink, useSelector} from "umi";
import get from "lodash/get";
import {selectCurrentJourneyConfig} from "@/services/selectors";

export default function MenuItem({item, children}){
  const journeyConfig = useSelector(selectCurrentJourneyConfig)
  if(item.name === 'UserManagement') item.locale = "menu.reports.performance"

  if (item.journeyConfigAccessKey){
    if (!get(journeyConfig, item.journeyConfigAccessKey)) return null
  }
  return <NavLink to={item.path}>
    {children}
  </NavLink>
}
