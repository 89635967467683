import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { getLanguageFromUmiLocale } from '@/utils/locale';
import { fetchCaptions, loadYouTubeIframeAPI, VIDEO_Id } from './util';

const YouTubeVideoWithCaptions = ({ height, width }) => {
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const userLocaleLanguage = getLanguageFromUmiLocale();

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      const newHeight = parseInt(height, 10) + 200;
      const newWidth = parseInt(width, 10) + 200;
      playerRef.current.setSize(newWidth, newHeight);
    }
  };

  // Initialize YouTube player with preferred caption language
  const initYouTubePlayer = () => {
    if (window.YT && window.YT.Player) {
      createPlayer();
    } else {
      window.onYouTubeIframeAPIReady = createPlayer;
    }
  };

  const createPlayer = () => {
    playerRef.current = new window.YT.Player('player', {
      height: height,
      width: width,
      videoId: VIDEO_Id,
      playerVars: {
        playsinline: 1,
        cc_load_policy: 1, // Show captions by default
        cc_lang_pref: userLocaleLanguage, // Set the preferred caption language
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  };

  const onPlayerReady = () => {
    showPlayer();
  };

  const showPlayer = () => {
    const playerElement = document.getElementById('player');
    if (playerElement) {
      playerElement.style.display = '';
      playerElement.style.opacity = '0';
      playerElement.style.transition = 'opacity 1s ease-in-out';
      setIsLoading(false);
      setTimeout(() => {
        playerElement.style.opacity = '1';
      }, 0);
    }
  };

  useEffect(() => {
    loadYouTubeIframeAPI()
      .then(() => initYouTubePlayer())
      .catch((error) => console.error('Failed to load YouTube API:', error));

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      window.onYouTubeIframeAPIReady = null; // Clean up global listener
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.destroy();
      initYouTubePlayer(); // Recreate the player with the new language
    }
  }, [userLocaleLanguage]);

  return (
    <div>
      {isLoading && <Spin />}
      <div id="player" style={{ display: 'none', opacity: 0 }}></div>
    </div>
  );
};

export default YouTubeVideoWithCaptions;
