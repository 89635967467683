import get from 'lodash/get';

import {
  getDisplayNamesForPath,
  getRolesForComponent,
  getRolesForPath,
  ROOMTAILOR,
  SUPER_ADMIN
} from '../typings/roleMapping';
import {UserModel} from "@/typings/models/User";

const isConversionalAdminRoles = (role: string) => role === SUPER_ADMIN || role === ROOMTAILOR
const getUserRole: (user: UserModel) => string = (user) => {
  return get(user, 'role.role', "");
}

export function checkUserAccessToPath(user: UserModel, path: string): boolean {
  const userRole = getUserRole(user)
  if(!userRole) return false;
  if (isConversionalAdminRoles(userRole)) return true;
  const pathRoles = getRolesForPath(path)
  return pathRoles.includes('*') || pathRoles.includes(userRole);
}
export function checkUserAccessToComponent(user: UserModel, component: string): boolean {
  const userRole = getUserRole(user)
  if(!userRole) return false;
  if (isConversionalAdminRoles(userRole)) return true;
  const componentRoles = getRolesForComponent(component)

  return componentRoles.includes('*') || componentRoles.includes(userRole);
}
export const getRoleCustomSidebarMenuDisplayName = (user: UserModel, path: string) => {
  const userRole = getUserRole(user)
  if(!userRole) return null;
  if (isConversionalAdminRoles(userRole)) return null;
  return getDisplayNamesForPath(path, userRole)
}
