export const DEFAULT_LOCALE = "de-DE"
import get from "lodash/get"

export const getDefaultBrowserLanguage = () => {
  const language = get(navigator, 'language', get(navigator, 'userLanguage'));
  const lang = language.slice(0,2)
  return convertLanguageToUmiLocale(lang)
}

export const initializeLocale = () => {
  const localeInitialized = localStorage.getItem("localeInitialized")
  if (!localeInitialized){
    localStorage.setItem("umi_locale" , getDefaultBrowserLanguage())
    localStorage.setItem("localeInitialized" , "1")
  }
}
export const getLanguageFromUmiLocale = (language?:string): string => {
  const umiLocale = language ?? localStorage.getItem("umi_locale");
  return umiLocale ? umiLocale.split("-")[0] : "de";
};
export const convertLanguageToUmiLocale = (language:string = ''): string => {
  if (typeof language !== "string") return DEFAULT_LOCALE

  const lang = language.slice(0,2)
  switch (lang) {
    case "de":
      return "de-DE"
    case "en":
      return "en-US"
    case "fr":
      return "fr-FR"
    case "nl":
      return "nl-NL"
    case "es":
      return "es-ES"
    default:
      return DEFAULT_LOCALE
  }
};
