export const API_PATH_PREFIX = '/partnerApi/v1';
export const BILLING_DETAILS_API_PATH = '/billings/:billingId'
export const BILLING_OVERVIEW_API_PATH = '/billings'
export const FORGOT_PASSWORD_API_PATH = '/password/forgot'
export const GET_CUSTOMERS_API_PATH = '/journeys/:journeyId/customers'
export const GET_CUSTOMER_API_PATH = '/customers/:customerId'
export const GET_RECENT_SERVICE_REQUEST_API_PATH = '/journeys/:journeyId/serviceRequests'
export const GET_RESULTS_STATS_API_PATH = '/journeys/:journeyId/results/stats'
export const GET_STYLES_STATS_API_PATH = '/journeys/:journeyId/styles/stats'
export const GET_ANALYTICS_FUNNEL_API_PATH = '/journeys/:journeyId/analytics'
export const GET_SUBSCRIBERS_STATS_API_PATH = '/journeys/:journeyId/subscribers/stats'
export const GET_SERVICE_REQUESTS_STATS_API_PATH = '/journeys/:journeyId/serviceRequests/stats'
export const GET_REGISTRATION_STATS_API_PATH = '/journeys/:journeyId/registrations/stats'
export const GET_CTA_CHART_API_PATH = '/journeys/:journeyId/conversions/chart'
export const GET_CTA_TABLE_API_PATH = '/journeys/:journeyId/conversions/table'
export const LOGIN_API_PATH = '/login'
export const LOGIN_ACCESS_TOKEN_API_PATH = '/auth/access-token'
export const LOGIN_REFRESH_TOKEN_API_PATH = '/auth/refresh-token'
export const GET_ME_API_PATH = '/me'
export const GET_SETTINGS_API_PATH = '/settings'
export const UPDATE_USER_SETTINGS_API_PATH = '/settings'
export const GET_ALL_USER_API_PATH = '/users'
export const CREATE_USER_API_PATH = '/users'
export const DELETE_USER_API_PATH = '/users/:userId'
export const GET_ROLES_API_PATH = '/roles'
export const GET_USER_BRANCHES_API_PATH = '/branches'

export const LOGOUT_API_PATH = '/logout'
export const RESET_PASSWORD_API_PATH = '/password/:token'
export const UNSUBSCRIBE_CUSTOMER_API_PATH = '/customers/:customerId'
export const GET_CUSTOMER_STATES_API_PATH = '/journeys/:journeyId/states'
export const CHANGE_CUSTOMER_STATE_API_PATH = '/customers/:customerId'
export const CHANGE_CUSTOMER_NOTES_API_PATH = '/customers/:customerId'
export const GET_LOCATIONS_API_PATH = '/journeys/:journeyId/locations'
export const CHANGE_LOCATION_API_PATH = '/locations/:locationId'
export const DELETE_LOCATION_API_PATH = '/locations/:locationId'
export const CREATE_LOCATION_API_PATH = '/journeys/:journeyId/locations'
export const GET_GLANCE_DASHBOARD_API_PATH = '/journeys/:journeyId/stats'

export const CREATE_RESOURCE_FOR_PROJECT = '/projects/:projectId/resources'
export const DELETE_RESOURCE_FOR_PROJECT = '/projects/:projectId/resources/:resourceId'

export const GET_RESULT_CONFIG = '/config/result/:journeyId'
export const SAVE_RESULT_CONFIG = '/config/result/:journeyId'

export const GET_JOURNEY_CONFIG = '/journeys/:journeyId'
export const EDIT_JOURNEY_CONFIG = '/journeys/:journeyId/configurations/:configurationKey'

export const GET_TAGS = '/journeys/:journeyId/tags'
export const CREATE_TAG = '/journeys/:journeyId/tags'
export const EDIT_TAG = '/journeys/:journeyId/tags/:tagId'
export const DELETE_TAG = '/journeys/:journeyId/tags/:tagId'

export const GET_TAG_GROUPS = '/journeys/:journeyId/taggroups'
export const CREATE_TAG_GROUP = '/journeys/:journeyId/taggroups'

export const GET_IMAGES = '/journeys/:journeyId/images'
export const UPLOAD_IMAGE = '/journeys/:journeyId/images/'
export const REMOVE_IMAGE = '/journeys/:journeyId/images'

export const DELETE_IMAGE_TAG = '/journeys/:journeyId/images/:imageId/tags/:tagId'
export const ADD_IMAGE_TAG = '/journeys/:journeyId/images/:imageId/tags/:tagId'

// region Email Promotions
export const FETCH_EMAIL_PROMOTION_LIST = '/journeys/:journeyId/emailPromotions'
export const FETCH_EMAIL_PROMOTION_TYPES = '/journeys/:journeyId/emailPromotionTypes'
export const ADD_EMAIL_PROMOTION = FETCH_EMAIL_PROMOTION_LIST
export const EDIT_EMAIL_PROMOTION = '/emailPromotions/:emailPromotionId'
export const DELETE_EMAIL_PROMOTION = EDIT_EMAIL_PROMOTION

export const FETCH_EMAIL_PROMOTION_CONDITIONS = '/journeys/:journeyId/promoConditions'
export const ADD_EMAIL_PROMOTION_CONDITION = FETCH_EMAIL_PROMOTION_CONDITIONS
export const EDIT_EMAIL_PROMOTION_CONDITION = '/promoConditions/:emailPromotionConditionId'
export const DELETE_EMAIL_PROMOTION_CONDITION = EDIT_EMAIL_PROMOTION_CONDITION

// endregion

export const FETCH_TAGS_STATS_2TAG_GROUPS = '/journeys/:journeyToken/tags/statistics/:tagGroup1Hashid/:tagGroup2Hashid'
export const FETCH_TAGS_STATS_3TAG_GROUPS = '/journeys/:journeyToken/tags/statistics/:tagGroup1Hashid/:tagGroup2Hashid/:tagGroup3Hashid'
