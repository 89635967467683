import React, { useEffect } from 'react';
import { Button, Input, Popover, Radio, Space } from 'antd';
import { ApiOutlined } from '@ant-design/icons';

import dev from '@/constants/environment/dev';
import production from '@/constants/environment/production';
import testing from '@/constants/environment/testing';
import staging from '@/constants/environment/staging';
import { CONVERSIONAL_ROLES, PARTNER } from '@/typings/roleMapping';
import { UserRole } from '@/typings/models/User';
import { DEFAULT_USER_ROLE, getPersistedUserRoleOnLocalStorage, setPersistedUserRoleOnLocalStorage } from './util';

const ENVIRONMENTS = [
  {
    title: 'Production',
    url: production.ROOT_URL,
  },
  {
    title: 'Testing',
    url: testing.ROOT_URL,
  },
  {
    title: 'Staging',
    url: staging.ROOT_URL,
  },
  {
    title: 'Development',
    url: dev.ROOT_URL,
  },
];

const EnvSwitcher: React.FC = () => {
  const [rootApiUrl, setRootApiUrl] = React.useState<string>(
    typeof localStorage !== 'undefined' ? localStorage.getItem('rootApiUrl') : null,
  );
  const devModeUserRole = getPersistedUserRoleOnLocalStorage() ?? DEFAULT_USER_ROLE;
  const [role, setRole] = React.useState<UserRole>(devModeUserRole);
  const isDevConfigured = rootApiUrl === dev.ROOT_URL;

  const onRootApiUrlChange = (url: string) => {
    setRootApiUrl(url);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('rootApiUrl', url);
    }
  };

  const onRoleChange =  (selectedRole: string) => {
    if(!isDevConfigured) return
    setRole({label:selectedRole,role:selectedRole});
    setPersistedUserRoleOnLocalStorage(selectedRole)
  };


  // @ts-ignore
  if (BACKOFFICE_ENV !== 'dev') return null;
  return (
    <Popover
      content={
        <div style={{ width: 450 }}>
          <Space direction={'vertical'}>
            <Radio.Group value={rootApiUrl} size={'small'}>
              {ENVIRONMENTS.map((env) => (
                <Radio.Button
                  value={env.url}
                  key={env.title}
                  onClick={() => onRootApiUrlChange(env.url)}
                >
                  {env.title}
                </Radio.Button>
              ))}
            </Radio.Group>
            <Input
              style={{ width: 400 }}
              size={'small'}
              placeholder={'Base API Url'}
              value={rootApiUrl}
              onChange={(e) => onRootApiUrlChange(e.target.value)}
            />
           {isDevConfigured && (
              <Radio.Group value={role.role} defaultValue={role.role} size={'small'}>
              {CONVERSIONAL_ROLES.map((role) => (
                <Radio.Button
                  value={role}
                  key={role}
                  onClick={() => onRoleChange(role)}
                >
                  {role}
                </Radio.Button>
              ))}
                 </Radio.Group>)
           }
          </Space>
        </div>
      }
    >
      <Button
        type={'primary'}
        size={'small'}
        style={{ position: 'fixed', top: 10, right: 10, zIndex: 100000 }}
        icon={<ApiOutlined />}
      >
        ENVIRONMENT
      </Button>
    </Popover>
  );
};

export default EnvSwitcher;
