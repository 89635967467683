import {URLParamBuilder} from "@/utils/functions";
import * as PATHS from "@/constants/path";
import {request} from "@/services/api/request";
import {
  ChangeLocationRequest,
  CreateLocationRequest,
  DeleteLocationRequest,
  GetLocationsRequest
} from "@/typings/api/getLocations";
import {CreateResourceRequest, DeleteResourceRequest} from "@/typings/api/resources";
import {DELETE_LOCATION_API_PATH} from "@/constants/path";
import { DeleteUserRequest } from "@/typings/api/user";

// region Fetch Location
type FetchLocationRequestOptions = {
  params: GetLocationsRequest["parameters"]
  query?: GetLocationsRequest["query"]
}
export function fetchLocationsRequest({params, query={}}: FetchLocationRequestOptions) {
  const url = URLParamBuilder(PATHS.GET_LOCATIONS_API_PATH, {
    params
  })

  return request<GetLocationsRequest["response"]>(url, {
    method: 'GET',
    params: query
  });
}
// endregion

// region Change Location
type ChangeLocationRequestOptions = {
  params: ChangeLocationRequest["parameters"]
  payload: ChangeLocationRequest["payload"]
}
export function changeLocationRequest({params, payload}: ChangeLocationRequestOptions) {
  const url = URLParamBuilder(PATHS.CHANGE_LOCATION_API_PATH, {
    params
  })

  return request<ChangeLocationRequest["response"]>(url, {
    method: 'PUT',
    data: payload,
  });
}
// endregion

// region Create Location
type CreateLocationRequestOptions = {
  params: CreateLocationRequest["parameters"]
  payload: CreateLocationRequest["payload"]
}
export function createLocationRequest({params, payload}: CreateLocationRequestOptions) {
  const url = URLParamBuilder(PATHS.CREATE_LOCATION_API_PATH, {
    params
  })

  return request<CreateLocationRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}
// endregion


// region Delete Location
type DeleteLocationRequestOptions = {
  params: DeleteLocationRequest["parameters"]
}
export function deleteLocationRequest({params}: DeleteLocationRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_LOCATION_API_PATH, {
    params
  })

  return request<DeleteUserRequest["response"]>(url, {
    method: 'DELETE'
  });
}
// endregion


// region CreateResource
type CreateResourceRequestOptions = {
  params: CreateResourceRequest["parameters"]
  payload: CreateResourceRequest["payload"]
}
export function createResourceRequest({params, payload}: CreateResourceRequestOptions) {
  const url = URLParamBuilder(PATHS.CREATE_RESOURCE_FOR_PROJECT, {
    params
  })

  return request<CreateResourceRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}
// endregion

// region Delete Resource
type DeleteResourceRequestOptions = {
  params: DeleteResourceRequest["parameters"]
}
export function deleteResourceRequest({params}: DeleteResourceRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_RESOURCE_FOR_PROJECT, {
    params
  })

  return request<DeleteResourceRequest["response"]>(url, {
    method: 'DELETE'
  });
}
// endregion
