import * as React from 'react'
import LogoAndTitle from "@/components/LogoAndTitle";
import styles from "./index.less"
import JourneyAccordion from "@/components/JourneyAccordion";
import {useModel} from 'umi';
import {RightOutlined} from '@ant-design/icons';
import classNames from "classnames";
import {checkUserAccessToComponent} from '@/utils/access';
import {RESTRTICTED_COMPONENTS} from '../../../../config/RestrictedComponents';

interface MenuHeaderProps {
  logo: string
}

const MenuHeader: React.FC<MenuHeaderProps> = ({logo}: MenuHeaderProps) => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const isJourneySwitcherRestricted = !checkUserAccessToComponent(initialState?.currentUser?.User, RESTRTICTED_COMPONENTS.JOURNEY_SWITCHER);


  return (
    <div
      className={classNames({
        [styles.menuHeader]: true,
        [styles.collapsed]: initialState?.collapsed,
      })}
    >
      <div
        className={styles.collapseSwitch}
        onClick={() =>
          setInitialState({
            ...initialState,
            collapsed: !initialState?.collapsed,
          })
        }
      >
        <RightOutlined />
      </div>
      <div
        className={isJourneySwitcherRestricted ? styles.logoWithoutJourneySelector : styles.logo}
      >
        <LogoAndTitle logo={logo} titleClassName={styles.title} />
      </div>
      {!isJourneySwitcherRestricted && (
        <div className={styles.journeySelector}>
          <JourneyAccordion
            miniClassName={styles.miniJourneySelector}
            mini={initialState?.collapsed}
          />
        </div>
      )}
    </div>
  );
}

export default (MenuHeader)
