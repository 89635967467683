export default {
  'menu.welcome': 'Willkommen',
  'menu.forgotpassword': 'Passwort vergessen',
  'menu.reports': 'Berichte',
  'menu.reports.dashboard': 'Dashboard',
  'menu.reports.marketing': 'Marketing',
  'menu.reports.performance': 'Performance',
  'menu.reports.cta': 'Call to Actions',
  'menu.Customers': 'Kunden',
  'menu.Requests': 'Termine',
  'menu.Locations': 'Standorte',
  'menu.Images': 'Bilder',
  'menu.Storybook': 'StoryBook',
  'menu.Support': 'Support',
  'menu.Language': 'Sprache',
  'menu.Settings': 'Einstellungen',
  'menu.more-blocks': 'Mehr Blocks',
  'menu.home': 'Start',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Unterseite',
  'menu.login': 'Login',
  'menu.register': 'Registrieren',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analyse',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Arbeitsplatz',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formular',
  'menu.UserManagement': 'Benutzerverwaltung',
  'menu.LocationManager.UserManagement': 'Verkäufer',


  // Don't pay attention to these phrases please Clemens. Thanks.
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
