import { transformObjectToLocaleMap } from '@/utils/intl';

export default {
  // region Login Page
  'pages.login.welcome': 'Bienvenue! Se connecter',
  'pages.layouts.userLayout.title': 'Démarrez votre entretien de vente de cuisine en ligne',
  'pages.login.username.placeholder': 'Adresse e-mail',
  'pages.login.password.placeholder': 'Mot de passe',
  'pages.login.rememberMe': 'Se souvenir de moi',
  'pages.login.forgotPassword': 'Mot de passe oublié?',
  'pages.login.dontHaveAnAccount': 'Pas de compte?',
  'pages.login.requestDemo': 'Demander une démo',
  'pages.login.submit': 'Connexion',
  'pages.login.password.required': 'Veuillez saisir votre mot de passe.',
  'pages.login.failure': 'La connexion a échoué, veuillez réessayer.',
  'pages.login.accountLogin.errorMessage': "Nom d'utilisateur/ mot de passe invalide",
  'pages.login.username.required': "Veuillez saisir votre nom d'utilisateur",
  'pages.login.success': 'Connexion réussie!',
  'pages.login.loginTitle': 'Bienvenue! Veuillez vous connecter',
  'pages.login.dontHaveAccount': 'Pas encore de compte?',
  'app.footer.imprint': 'Mentions légales',
  'app.footer.policy': 'Directive',
  'pages.promotions.promotionCondition': "Conditions d'utilisation",
  'pages.promotions.addPromotionCondition': 'Ajouter des conditions générales',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Veuillez sélectionner',

  // endregion

  // region Reset Password Page
  'pages.resetPassword.setNewPassword': 'Définir un nouveau mot de passe',
  'pages.resetPassword.placeholder': 'Mot de passe',
  'pages.resetPassword.placeholderConfirm': 'Confirmez le mot de passe',
  'pages.resetPassword.passwordRequired': 'Veuillez saisir votre mot de passe',
  'pages.resetPassword.backToLogin': 'Retour à la page de connexion',
  'pages.resetPassword.title': 'Veuillez définir un nouveau mot de passe',
  'pages.resetPassword.subTitle': 'Veuillez saisir un nouveau mot de passe',
  'pages.resetPassword.errorMessage':
    'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
  'pages.resetPassword.wrongConfirmation':
    'Les deux mots de passe que vous avez saisis ne correspondent pas.',
  'pages.forgotPassword.hint':
    'À quelle adresse e-mail souhaitez-vous envoyer les informations de réinitialisation du mot de passe?',
  'pages.forgotPassword.forgotPasswordTitle': 'Réinitialiser le mot de passe',
  'pages.forgotpassword.required':
    'Ce champ est obligatoire, veuillez saisir votre adresse e-mail.',
  // endregion

  // region Forgot Password Page
  'pages.forgotPassword.label': 'Adresse e-mail',
  'pages.forgotPassword.required':
    'Ce champ est obligatoire, veuillez saisir votre adresse e-mail.',
  'pages.forgotPassword.placeholder': 'Adresse e-mail',
  'pages.forgotPassword.tooltip':
    "Si vous ne vous souvenez pas de votre adresse e-mail, veuillez nous contacter à l'adresse support@conversional.de",
  'pages.forgotPassword.errorMessage':
    "Il y a une erreur avec votre adresse e-mail, veuillez contacter le service d'assistance via la page de contact",
  'pages.forgotPassword.invalid':
    "L'adresse e-mail n'est pas enregistrée, veuillez corriger l'adresse.",
  'pages.forgotPassword.submit': 'Envoyer la demande',
  'pages.forgotPassword.login': 'Retour à la connexion',
  'pages.forgotPassword.guidText':
    'À quelle adresse e-mail souhaitez-vous envoyer les informations de réinitialisation du mot de passe?',
  'pages.forgotPassword.sentMail':
    "L'e-mail de réinitialisation du mot de passe a bien été envoyé.",
  'pages.forgotPassword.sentMailSubtitle': 'Veuillez également vérifier votre spam.',
  // endregion

  // region Dashboard Page
  'pages.dashboard.header': 'Tableau de bord',
  // endregion

  // region Marketing Page
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'Aucun graphique disponible. Changez le filtre de date.',
  // endregion

  // region Performance Page
  'pages.performance.header': 'Performance',
  'pages.performance.alertMessage': 'Ceci est la page de performance',
  'pages.performance.cardTitle': 'Entonnoir de conversion',
  'pages.performance.instantAppointmentPath': 'Parcours Rendez-vous',
  'pages.performance.inspirationPath': 'Parcours Inspiration',
  'pages.performance.infoText':
    "Ces données sont basées sur les événements de suivi. En raison des bloqueurs de suivi et de l'échantillonnage, ces données ne représentent pas tous les utilisateurs. Il se peut que les valeurs affichées soient différentes de celles du tableau de bord.",
  'pages.performance.paths': "Chemin d'accès",
  'pages.performance.explanation':
    'Les chemins d\'accès sont démarrés à partir du questionnaire. Leurs valeurs "démarrées" se rapportent au chemin, pas aux démarrages des outils. Par conséquent, les démarrages de tous les chemins sont inférieurs aux démarrages des outils lorsque les utilisateurs abandonnent à la première étape.',
  // endregion

  // region Customers Page
  'pages.customers.header': 'Clients',
  'pages.customers.tableTitle': 'Clients',
  'pages.customers.engagementScore': "Score d'Engagement",
  'pages.customers.scoreExplanation':
    'Probabilité estimée que ce client achète dans votre magasin en fonction de toutes les interactions précédentes.',
  'pages.customers.name': 'Nom',
  'pages.customers.gender': 'Sexe',
  'pages.customers.email': 'E-mail',
  'pages.customers.utmMedium': 'Moyen de contact',
  'pages.customers.status': 'Statut',
  'pages.customers.avtiveRecipient': 'Abonné',
  'pages.customers.doubleOptin': 'Double opt-in',
  'pages.customers.createDate': 'Date de création',
  'pages.customers.actions': 'Actions',
  'pages.customers.details': 'Détails',
  'pages.customers.actions.download': 'Télécharger CSV',
  'pages.customers.profile': 'Profile',
  'pages.customers.firstName': 'Prénom',
  'pages.customers.lastName': 'Nom',
  'pages.customers.styles': 'Styles',
  'pages.customers.qualification': 'Qualification',
  'pages.customers.phone': 'Téléphone',
  'pages.customers.activeRecipient': 'Destinataire actif',
  'pages.customer.unsubscribe': 'Désabonner',
  'pages.customer.qualification': 'Qualification',
  'pages.customer.state': 'Statut',
  'pages.customer.orderVolume': 'Volume de commande',
  'pages.customer.notes': 'Notes',
  'pages.customer.results': 'Résultats',
  'pages.customer.uploadedFiles': 'Fichiers Téléchargés',
  'pages.customer.noUploadedFiles': 'Aucun Fichier Téléchargé',

  // endregion

  // region Requests Page
  'pages.requests.header': 'Clients',
  'pages.requests.tableTitle': 'Rendez-vous',
  'pages.requests.customer': 'Client',
  'pages.requests.appointmentRequest': 'Date de rendez-vous',
  'pages.requests.what': 'Quoi',
  'pages.requests.dateOfRequest': 'Date de création',
  'pages.requests.where': 'Où',
  'pages.requests.actions': 'Actions',
  'pages.requests.details': 'Détails',
  // endregion

  // region Locations page
  'pages.locations.header': 'Sites',
  'pages.locations.alertMessage': 'Ceci est la page des sites',
  'pages.locations.location': 'Magasin',
  'pages.locations.address': 'Adresse',
  'pages.locations.videoCall': 'Appel vidéo',
  'pages.locations.created': 'Date de création',
  'pages.locations.actions': 'Actions',
  'pages.locations.details': 'Détails',
  'pages.common.clearAll': 'Tout supprimer',
  'pages.location.edit': 'Modifier les données',
  'pages.locations.availability': "Heures d'ouverture",
  'pages.locations.blockHours': "Heures d'indisponibilité",
  'pages.locations.additionalOpenHours': "Heures d'ouverture supplémentaires",
  'pages.locations.from': 'Du',
  'pages.locations.to': 'Au',
  'pages.locations.fromHour': 'De',
  'pages.locations.toHour': 'à',
  'pages.location.removeRecurringTime': 'Voulez-vous supprimer ces horaires récurrentes?',
  'pages.locations.download': 'Télécharger CSV',
  'pages.common.add': 'Ajouter',
  'pages.common.remove': 'Rejeter',
  'pages.common.actions': 'Actions',
  'pages.common.clear': 'Déselectionner',
  'pages.common.save': 'Sauvegarder',
  'pages.common.discard': 'Jeter',
  'pages.common.selected': 'Sélectionné',
  'pages.common.edit': 'Éditer',
  'pages.common.yes': 'oui',
  'pages.common.no': 'non',
  'pages.common.ok': 'OK',
  'pages.common.cancel': 'Annuler',
  'pages.common.tutorial': 'Tutoriel',
  'pages.common.confirm': 'Confirmer',
  'pages.images.deleteSelectedImagesSuccess': 'Images supprimées avec succès',
  'pages.location.removeBlockPeriod': "Voulez-vous supprimer ces heures d'indisponibilité?",
  'pages.location.removeOpenPeriod':
    "Êtes-vous sûr de vouloir supprimer ces heures d'ouverture supplémentaires?",
  'pages.location.name': 'Nom',
  'pages.location.home': 'À domicile',
  'pages.location.store': 'Magasin',
  'pages.location.online': 'En ligne',
  'pages.location.type': 'Type',
  'pages.location.type_tooltip': 'Le type de lieu définit le type de rendez-vous pris.',
  'pages.location.address': 'Adresse',
  'pages.location.createDate': 'Date de création',
  'pages.location.videoCallEnabled': 'Actif',
  'pages.location.videoCallDisabled': 'Inactif',
  'pages.location.videoCall': 'Appel vidéo',
  'pages.location.details': 'des détails',
  'pages.locations.resourceCountLimitation':
    '**** You cannot add more than 5 resources to a location. If you need more resources please contact support.',
  'pages.locations.atLeastOneResource': '**** Each location requires at least one resource.',
  'pages.locations.currentRecurring': 'Heure récurrente actuelle',
  'pages.locations.resources': 'Destinataires',
  'pages.locations.meta': 'Autres',
  'pages.locations.removeResourceSuccess': 'Ressource supprimée.',
  'pages.locations.removeResourceFailed': "La ressource n'a pas pu être supprimée.",
  'pages.locations.addNewResource': 'Ajouter un nouveau destinataire',
  'pages.locations.addNewResourceSuccess': 'Nouvelle ressource ajoutée.',
  'pages.locations.addNewResourceFailed': "La ressource n'a pas pu être ajoutée.",
  'pages.location.resource.name': 'Nom',
  'pages.location.resource.email': 'E-mail',
  // Location Removal
  'pages.locations.removeLocationConfirm': 'Êtes-vous sûrs de vouloir supprimer ce lieu ?',
  'pages.locations.removeLocationSuccess': 'Lieu supprimé avec succès',
  'pages.locations.removeLocationFailed': 'La suppréssion du lieu a échoué',
  //
  // endregion

  // region Account Settings
  'pages.settings.header': 'Paramètres du compte',
  'pages.settings.profileTab': 'Profile',
  'pages.settings.billingTab': 'Facturation',
  'pages.settings.languageTab': 'Langue',
  'pages.settings.firstName.label': 'Prénom',
  'pages.settings.firstName.required': 'Ce champ est obligatoire, veuillez saisir votre prénom',
  'pages.settings.firstName.placeholder': 'Prénom',
  'pages.settings.firstName.errorMessage':
    'Votre prénom ne peut contenir ni chiffres, ni symboles. Veuillez corriger votre prénom',
  'pages.settings.lastName.label': 'Nom de famille',
  'pages.settings.lastName.required':
    'Ce champ est obligatoire, veuillez saisir votre nom de famille',
  'pages.settings.lastName.placeholder': 'Nom de famille',
  'pages.settings.lastName.errorMessage':
    'Votre prénom ne peut contenir ni chiffres, ni symboles. Veuillez corriger votre nom de famille',
  'pages.settings.email.label': 'Adresse e-mail',
  'pages.settings.email.required': 'Ce champ est obligatoire, veuillez saisir votre adresse e-mail',
  'pages.settings.email.placeholder': 'Adresse e-mail',
  'pages.settings.email.errorMessage':
    'Il y a une erreur avec votre adresse e-mail, veuillez saisir un format valide',
  'pages.settings.phone.label': 'Téléphone',
  'pages.settings.phone.required': 'Ce champ est obligatoire, veuillez saisir votre téléphone',
  'pages.settings.phone.placeholder': 'Téléphone',
  'pages.settings.phone.errorMessage':
    'Votre numéro de téléphone ne peut pas contenir de lettre, veuillez corriger le numéro',
  'pages.settings.address.label': 'Adresse',
  'pages.settings.address.required': 'Ce champ est obligatoire, veuillez saisir votre adresse',
  'pages.settings.address.placeholder': 'Adresse',
  'pages.settings.saveSettings': 'Enregistrer les changements',

  // region Account Settings page - Billings tab
  'pages.billings.header': 'Facturation',
  'pages.billings.alertMessage': 'Ceci est la page de facturation',
  'pages.billings.email': 'E-mail',
  'pages.billings.questionnaire': 'Questionnaire',
  'pages.billings.customer': 'Client',
  'pages.billings.journey': 'Parcours',
  'pages.billings.event': 'Évènement',
  'pages.billings.period': 'Période',
  'pages.billings.created': 'Créé',
  'pages.billings.mode': 'Mode',
  'pages.billings.subtotal': 'Sous-total',
  'pages.billings.discount': 'Réduction',
  'pages.billings.total': 'Total',
  'pages.BillingDetails': 'Détails de facturation',
  // endregion
  // region Account Settings page - Language tab
  'pages.language.title': 'Choisissez votre langue',
  'pages.language.english': 'Anglais',
  'pages.language.german': 'Allemand',
  'pages.language.french': 'Français',
  // endregion
  // endregion

  // region Profile Page
  'pages.profile.firstName': 'Prénom',
  'pages.profile.lastName': 'Nom',
  'pages.profile.email': 'E-mail',
  'pages.profile.phone': 'Téléphone',
  'pages.profile.address': 'Adresse',
  'pages.profile.saveChanges': 'Sauvegarder les changements',
  // endregion

  // region Support Page
  'pages.support.header': 'Assistance',
  'pages.settings.telephone': 'Téléphone',
  'pages.settings.email': 'E-mail',
  'pages.support.telephone': 'Par téléphone',
  'pages.support.email': 'Par e-mail',
  'pages.support.title': 'Contactez notre assistance technique',
  // endregion

  // region Service Request
  'pages.serviceRequest.customer': 'Client',
  'pages.customers.created': 'Créé le',
  'pages.serviceRequest.inquiry': 'Demande',
  'pages.serviceRequest.what': 'Quoi',
  'pages.serviceRequest.when': 'Quand',
  'pages.serviceRequest.where': 'Où',
  'pages.serviceRequest.qualification': 'Qualification',
  // endregion

  // region Date and Weekdays
  'utils.date.7Days': 'Les 7 derniers jours',
  'utils.date.30Days': 'Les 30 derniers jours',
  'utils.date.90Days': 'Les 90 derniers jours',
  'utils.date.180Days': 'Les 180 derniers jours',
  // endregion

  // region Images Page

  'pages.images.tagGroups': 'Groupe de tag',
  'pages.images.createTagGroup': 'Créer un Groupe de tag',
  'pages.images.editTag': 'éditer tag',

  'pages.images.tags': 'Tags',
  'pages.images.removeImageConfirmation':
    'Voulez-vous vraiment supprimer cette image? Cette action est irréversible.',
  'pages.images.chooseGroup': 'Sélectionner un groupe pour ce tag.',
  'pages.images.newTag': 'Nouveau tag',
  'pages.images.createTag': 'Créer un tag',
  'pages.images.remove': 'Supprimer',
  'pages.images.removeTagGloballyTitle': 'Voulez-vous vraiment supprimer ce tag?',
  'pages.images.removeTagGloballySubTitle': 'Le tag sera supprimé de toutes les images.',
  'pages.images.upload': 'Télécharger',
  'pages.images.uploadDrawerTitle': "Télécharger l'image",
  'pages.images.uploadDraggerHint': "Télécharger l'image avec la plus haute résolution",
  'pages.images.uploadDraggerText':
    'Pour télécharger, cliquer ou faire glisser des fichiers ou des dossiers sur cette zone.',
  'pages.images.file': 'Fichier',
  'pages.images.directory': 'Dossier',
  'pages.images.selectToBulkEdit': 'Sélectionnez les images que vous souhaitez taguer en bloc',
  'pages.images.selectTagStatistics': 'Statistiques',
  'pages.images.deleteSelectedImagesConfirm': 'Voulez-vous supprimer (x) images ?',
  'pages.images.contactSupportToSetUpImageGallery':
    "Vous n'utilisez pas actuellement de galerie d'images personnalisée. Si vous souhaitez avoir plus de contrôle sur vos propres images, n'hésitez pas à nous contacter.",
  'pages.images.noCustomImageGalleryAvailable':
    "Vous n'avez pas encore de galerie d'images personnalisée.",
  'pages.images.requestAccess': 'Demande de galerie personnalisée',

  // endregion

  // region CTA Page
  'pages.cta.performance': 'Click / Convertir',
  'pages.cta.page': 'Page',
  'pages.cta.conversions.help':
    'utilisateurs ayant complété le questionnaire avec et sans rendez-vous',
  // endregion

  // region Promotions Page

  'pages.promotions.help_1': "Qu'est-ce qu'une promotion ?",
  'pages.promotions.help_2':
    "Vous pouvez ajouter automatiquement des activités promotionnelles à vos courriels. Il vous suffit de créer et d'activer une promotion pour qu'elle soit automatiquement annoncée à vos clients pendant la période donnée.",
  'pages.promotions.tableTitle': 'Promotions',
  'pages.promotions.showVideoTutorial': 'Voir le tutoriel vidéo',
  'pages.promotions.newPromotion': 'Nouvelle promotion',
  'pages.promotions.addPromotion': 'Ajouter',
  'pages.promotions.editPromotion': 'Traiter la promotion',
  'pages.promotions.removePromotionConfirm': 'Êtes-vous sûr de vouloir supprimer cette promotion ?',
  'pages.promotions.type': 'Type de promotion',
  'pages.promotions.modifiedDate': 'Dernière modification',
  'pages.promotions.title': 'Titre',
  'pages.promotions.start': 'Lancement',
  'pages.promotions.end': 'Fin',
  'pages.promotions.dates': 'Durée de validité',
  'pages.promotions.removePromotionsSuccess': 'Promotion supprimée',
  'pages.promotions.removePromotionsFailed': "La promotion n'a pas pu être supprimée",
  'pages.promotions.form': 'Formulaire de promotion',
  'pages.promotions.preview': 'Aperçu',
  'pages.promotions.invalid_error':'Veuillez saisir une URL valide',

  // endregion

  //tag statistics
  'pages.stats.selectTagGroups':
    "Sélectionnez jusqu'à 3 groupes de balises que vous aimeriez comparer",
  'pages.stats.tagCombination': 'Combinaison de balises',
  'pages.stats.frequency': 'Fréquence',
  //endregion

  // region Settings - visibility
  'pages.settings.visibility.active': 'Actif',
  'pages.settings.visibility.active.tooltip':
    "Vous pouvez activer/ désactiver l'outil sur l'ensemble des pages",
  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: "Vous pouvez définir des règles d'affichage en filtrant certaines pages en saisissant leur URL voire certains paramètres",
    exampleTitle: 'Exemples',
    urls: [
      {
        value: '/cuisine/i',
        description: 'Toutes les pages de la section "cuisine"',
      },
      {
        value: '/cuisine/(.*)/i',
        description: 'Toutes les sous-pages de la section "cuisine"',
      },
      {
        value: '/https://domain.com/cuisine/i',
        description: 'Toutes les pages qui commencent par "https://domain.com/cuisine"',
      },
    ],
  }),
  'pages.settings.visibility.blackList': 'Exclure les pages',
  'pages.settings.visibility.blackList.tooltip':
    "L'outil sera affiché sur toutes les pages sauf celles listées ci-dessous",
  'pages.settings.visibility.whiteList': 'Afficher seulement sur',
  'pages.settings.visibility.clearRules':
    "Veuillez supprimer l'entité des règles avant de changer le type de paramétrage.",
  'pages.settings.visibility.whiteList.tooltip':
    "L'outil sera seulement affiché sur les pages listées ci-dessous",
  'pages.settings.configuration.editSuccessMessage': 'Paramètres mis à jour avec succès !',
  // endregion

   // region ForbiddenPage Page
   'pages.ForbiddenPage.message': 'Vous n\'avez pas accès à cette page',
   'pages.users.newUser': 'Nouvel utilisateur',
   'pages.users.removeUserConfirmation':'Êtes-vous sûr de vouloir supprimer l\'utilisateur?',
   'pages.users.tableTitle':'Utilisateurs',
   'pages.users.user':'Utilisateur',
   'pages.users.email':'E-mail',
   "pages.users.role":"Rôle",
   'pages.users.selectPlaceholder': 'Veuillez sélectionner un rôle',
   'pages.users.addUser': 'Ajouter un utilisateur',
  'pages.users.close': 'Fermer',
  'pages.users.phone':'Téléphone',
  "pages.users.removeUserSuccess":'Utilisateur supprimé avec succès',
  "pages.users.removeUserFailed": 'Echec de la suppression de l\'utilisateur',
  "pages.users.createUserSuccess":'Utilisateur créé avec succès',
  "pages.users.createUserFailed":'Echec de la création de l\'utilisateur',
    "pages.users.phone.invalidFormat":'Veuillez saisir un numéro de téléphone valide',
  'pages.users.phone.errorMessage': 'Votre numéro de téléphone ne peut pas contenir de lettres, veuillez corriger le'

   // endregion
};
