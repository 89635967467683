import {Result} from 'antd';
import React from 'react';
import {FormattedMessage as F} from 'umi';

const ForbiddenPage: React.FC = () => (
  <Result
    status="403"
    title='403'
    subTitle={
      <F
        id="pages.ForbiddenPage.message"
        defaultMessage="You do not have permission to access this page."
      />
    }
    //" You do nothave permission to access this page."
  />
);

export default ForbiddenPage;
